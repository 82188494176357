@use './table' as *;

html,
body,
div#root {
   height: 100%;

   * {
      font-family: 'Work Sans', Arial, Helvetica, sans-serif !important;
   }
}

body {
   margin: 0;
   padding: 0;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

.c1wupbe7-0-0-beta-20 {
   border: 1px solid #ddd !important;
}

svg.main-svg {
   width: 100% !important;
   max-width: 100% !important;
   //    border: 2px solid red !important;
}

.tss-1qtl85h-MUIDataTableBodyCell-root.tss-1vd39vz-MUIDataTableBodyCell-stackedCommon.datatables-noprint {
   .chakra-spinner {
      min-height: 20px;
      min-width: 20px;
   }
}

/**
FILE PREVIEW
**/

.spreadsheet-viewer .sheet-names {
   z-index: unset !important;
}

.spreadsheet-viewer .sheet-names button.active,
.spreadsheet-viewer .sheet-names button:hover {
   color: white;
}

.h-mui-table .MuiTable-root table tr td,
.MuiTable-root table tr td {
   border: none !important;
}

.css-26l3qy-menu {
   z-index: 100 !important;
}

body.tox-fullscreen .tox.tox-tinymce.tox-fullscreen {
   z-index: 1401;
}

.tox.tox-silver-sink.tox-tinymce-aux {
   z-index: 1401;
}

div.react-datepicker-wrapper {
   display: block;
}

.css-sgkoz8.chakra-button__group > button.last-btn:not(:first-of-type):not(:last-of-type) {
   border-bottom-right-radius: 5px;
   border-top-right-radius: 5px;
}

.css-sgkoz8.chakra-button__group > button.first-btn {
   border-bottom-left-radius: 5px;
   border-top-left-radius: 5px;
}

::-webkit-scrollbar-track {
   background-color: transparent;
   // background-color: #ddd;
 }
 
 ::-webkit-scrollbar {
   right: 0;
   width: 8px;
   height: 8px;
 }
 
 ::-webkit-scrollbar-thumb {
   background-color: #999;
   border-radius: 8px;
   width: 8px;
   height: 8px;
 }