.h-mui-table {
   table {
      min-width: 100% !important;
      table-layout: auto;
   }

   thead {
      th div,
      tr th button div div {
         font-size: 1em !important;
         font-weight: bold;
      }
   }

   tbody tr:first-child td {
      font-weight: 400 !important;
   }

   tbody,
   tfoot {
      tr {
         td:not(.has-border) {
            border-right: none !important;
            border-left: none !important;
            border-top: none !important;
         }
      }
   }
}

.table-sm {
   thead {
      th {
         padding: 8px 0;

         .css-rajk3t-MuiButtonBase-root-MuiButton-root {
            padding: 6px 8px;
         }
      }

      th:first-child {
         padding: 8px;
      }
   }
   tbody {
      tr {
         td {
            padding: 8px;
         }

         td.indent-cell {
            padding-left: 14px;
         }
      }
   }
}

.table-xs {
   thead {
      th {
         padding: 4px;
      }
   }
   tbody {
      tr {
         td {
            padding: 4px;
         }

         td.indent-cell {
            padding-left: 14px;
         }
      }
   }
}

.table-striped.table-striped-light {
   tbody tr.MuiTableRow-root:nth-of-type(2n + 1) {
      background: rgba(0, 0, 0, 0.04);
   }
}

.table-striped.table-striped-dark {
   tbody tr.MuiTableRow-root:nth-of-type(2n + 1) {
      background: rgba(255, 255, 255, 0.08);
   }
}

.MuiPaper-root.MuiPopover-paper {
   min-width: 30%;
   height: fit-content;
   overflow: visible;
}

.MuiPopover-root.MuiModal-root .MuiPopover-paper {
   left: unset !important;
   right: 2.6%;
}

.h-mui-table tr td .table-actions,
.h-mui-table tr td .table-actions-flex {
   display: none;
   transition: all ease-in-out 0.5s;
}

.h-mui-table tr:active td .table-actions,
.h-mui-table tr:hover td .table-actions {
   display: block;
}

.h-mui-table tr:active td .table-actions-flex,
.h-mui-table tr:hover td .table-actions-flex {
   display: flex;
   justify-content: center;
}

@media (max-width: 900px) {
   .h-mui-table {
      tbody tr td {
         padding: 3px 4px;

         div {
            font-size: 95%;
         }

         > div:first-child.tss-1vd39vz-MUIDataTableBodyCell-stackedCommon:nth-last-of-type(2) {
            display: none;
         }

         > div:nth-child(2) {
            width: 100%;
         }
      }
   }

   .MuiPaper-root.MuiPopover-paper {
      min-width: 40%;
   }
}

@media (max-width: 767px) {
   .MuiPaper-root.MuiPopover-paper {
      min-width: 60%;
   }
}

@media (max-width: 425px) {
   .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
      max-width: 48vw;
      overflow: scroll;
   }
}

.email-content {
   table {
      min-width: unset;
      tr {
         td {
            border: none;
         }
      }
   }
}
