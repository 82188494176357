.error-boundary-btn {
   display: -webkit-inline-box;
   display: -webkit-inline-flex;
   display: -ms-inline-flexbox;
   display: inline-flex;
   -webkit-appearance: none;
   -moz-appearance: none;
   -ms-appearance: none;
   appearance: none;
   -webkit-align-items: center;
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   -webkit-justify-content: center;
   justify-content: center;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   position: relative;
   white-space: nowrap;
   vertical-align: middle;
   outline: 2px solid transparent;
   outline-offset: 2px;
   line-height: 1.2;
   border-radius: 0.375rem;
   font-weight: 600;
   transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
      transform;
   transition-duration: 200ms;
   height: 2rem;
   min-width: 2rem;
   font-size: 0.875rem;
   -webkit-padding-start: 0.75rem;
   padding-inline-start: 0.75rem;
   -webkit-padding-end: 0.75rem;
   padding-inline-end: 0.75rem;
   background: #168ad9;
   border-color: #168ad9;
   cursor: pointer;
   color: #fff;
   -webkit-margin-start: 0px;
   margin-inline-start: 0px;
   -webkit-margin-end: 0px;
   margin-inline-end: 0px;
}
